import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const StyledForm = styled.div`
  background: white;
  padding: 55px 45px;
  text-align: left;
  box-shadow: 0 0 8px 0px grey;
  

  .hidden {
    display: none;
  }

  input[type="number"] {
    text-align: center;
    
    &:before {
      content:"€";
      left: 5px;
    }
  }
  
  form {
    
    display: flex;
    flex-direction: column;
    
    input {
      background: white;
    }
    
    .field{
      margin-top: 8px;
      margin-bottom: 8px;
    }
  
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    } 
    
    button {
      background-color: #D21239;
      color: white;
      margin-top: 16px;
      
      &:hover {
        background-color: #bb1033;
        color: white;
      }
    }
  }
}  
`;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      prefix="€ "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

class DonateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", amount: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit(event) {
    console.log("A form was submitted: ");
    console.log(this.state);
    event.preventDefault();

    fetch(
      `/.netlify/functions/donate?name=${this.state.name}&amount=${
        this.state.amount
      }&email=${this.state.email}`
    )
      .then(res => res.json())
      .then(res => (window.location = res.paymentUrl));
  }

  render() {
    return (
      <StyledForm>
        <div>
          <h2>Make a donation</h2>
          <span>And help us educate Pakistan</span>
        </div>
        <form onSubmit={this.handleSubmit}>
          <p className="hidden">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <TextField
            label="Amount"
            className="amount field"
            value={this.state.amount}
            onChange={this.handleChange("amount")}
            margin="normal"
            variant="filled"
            InputProps={{
              inputComponent: NumberFormatCustom
            }}
          />
          <TextField
            label="Name"
            className="field"
            value={this.state.name}
            onChange={this.handleChange("name")}
            margin="normal"
            variant="filled"
          />
          <TextField
            className="field"
            label="Email"
            type="email"
            autoComplete="email"
            margin="normal"
            variant="filled"
            value={this.state.email}
            onChange={this.handleChange("email")}
          />

          <Button type="submit" className="fontButton" variant="contained">
            Next
          </Button>
        </form>
      </StyledForm>
    );
  }
}

export default DonateForm;
