import React from "react";
import Layout from "../components/Layout";
import DonateForm from "../components/donate/Form";
import styled from "styled-components";
import img from "../../static/img/bill-568817-unsplash.jpg";

const Wrapper = styled.div`
  background-image: url("${img}");
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
  
  .content {
    display: flex;
    justify-content: center;
    
    @media only screen and (min-width: 1216px) {
      width: 85%;
      justify-content: flex-end;
    } 
  }
`;

const Form = styled.div`
  //background: white;
`;
export const DonatePage = props => (
  <Layout>
    <Wrapper>
      <section className="section donate">
        <div className="container">
          <div className="content">
            <Form>
              <DonateForm />
            </Form>
          </div>
        </div>
      </section>
    </Wrapper>
  </Layout>
);

export default DonatePage;
